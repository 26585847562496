define(['app', 'componentHelper'], function(app, componentHelper) {

  const productPageStockAvailability = function() {
    const component = {};

    const _config = {
      selectors: {
        stockAvailability: '[data-component=productPageStockAvailability]',
        stockAvailabilityModal: '[data-component=productPageStockAvailabilityModal]',
        checkStockAvailabilityCTA: '.productAddToBasket_clickAndCollect',
        checkStockAvailabilityCTAButton: '.clickAndCollectDisplayModal',
        modalDialog: '.modal_dialog',
        homeDeliveryButton: '.productAddToBasket_homeDelivery',
        stockAvailabilityButton: '.productPageStockAvailability_checkLocalStore',
        clickAndCollectButton: '.clickAndCollectDisplayModal',
        productPageStockAvailabilityModal: '.productPageStockAvailabilityModal',
        productAlternatives: '.productAlternativesWrapper'
      },
      channels: {
        update: 'productPageAvailabilityUpdate/update',
        openStockCheckClick: 'openStockCheck/click'
      },
      classNames: {
        modalDialogShow: 'modal_dialog-show',
        hideHomeDelivery: 'hideHomeDeliveryButton',
        addToDelivery: 'productAddToBasket-buyNow'
      },
      attribs: {
        homeDelivery: 'data-home-delivery'
      }
    };

    const _init = (element, userDriven) => {
      component.element = element;
      component.addEventListener();
      if (!userDriven) {
        app.subscribe(component.config.channels.update, component.update);
      }
      return component;
    };

    const _addEventListener = () => {
      let checkStockAvailabilityCTAButtons = document.querySelectorAll(component.config.selectors.checkStockAvailabilityCTAButton);

      if (checkStockAvailabilityCTAButtons && checkStockAvailabilityCTAButtons.length > 0) {
        checkStockAvailabilityCTAButtons.forEach( checkStockAvailabilityCTAButton => {
          checkStockAvailabilityCTAButton.addEventListener('click', component.openCheckStockAvailabilityModal);
          checkStockAvailabilityCTAButton.parentElement.classList.remove('hideCCButton');
        });
      }

      const stockAvailabilityButton = document.querySelector(component.config.selectors.stockAvailabilityButton);
      if(stockAvailabilityButton) {
        stockAvailabilityButton.addEventListener('click', component.publishChannel);
      }
    };

    const _publishChannel = () => {
      app.publish(component.config.channels.openStockCheckClick)
    }

    const _update = (productId) => {
      let requestUrl = "/" + productId + ".checkStockCTA";
      app.ajax.get({
        url: requestUrl,
        success: component.updateCheckStockAvailabilityCTA,
        error: component.checkStockCTAErrorHandler
      });

      requestUrl = "/" + productId + ".stockAvailability";
      app.ajax.get({
        url: requestUrl,
        success: component.successHandler,
        error: component.errorHandler,
      });
    };

    const _successHandler = (response) => {
      component.container = component.element.parentNode;
      component.container.innerHTML = response;
      const element = component.container.querySelector(component.config.selectors.stockAvailability);
      component.cleanupExistingStockAvailabilityModal();
      component.init(element, true);
      componentHelper.reloadAllComponents(element);
      component.toggleProductAddToBasket();
      component.showOutOfStockMessage();
    };

    const _showOutOfStockMessage = () => {
      const productAlternatives = document.querySelector(component.config.selectors.productAlternatives);
      const inStockProduct = component.element.getAttribute("data-in-stock");
      if(productAlternatives) {
        if (inStockProduct === "true") {
          productAlternatives.style.display = "none";
        } else {
          productAlternatives.style.display = "block";
        }
      }
    };

    const _updateCheckStockAvailabilityCTA = (response) => {
      const checkStockAvailabilityCTAs = document.querySelectorAll(component.config.selectors.checkStockAvailabilityCTA);
      if (checkStockAvailabilityCTAs) {
        checkStockAvailabilityCTAs.forEach(checkSTockCTA => {
          checkSTockCTA.innerHTML = response;
        });
      }
      component.addEventListener();
    };

    const _errorHandler = () => {
      console.error('ERROR: Could not retrieve new product stock availability data');
    };

    const _checkStockCTAErrorHandler = () => {
      console.error('ERROR: Could not retrieve new product check stock availability cta');
    };

    const _cleanupExistingStockAvailabilityModal = () => {
      const modals = document.querySelectorAll(component.config.selectors.modalDialog);
      modals.forEach(modal => component.deleteIfStockAvailabilityModal(modal));
    };

    const _deleteIfStockAvailabilityModal = (modal) => {
      const stockAvailabilityModalComponent = modal.querySelector(component.config.selectors.productPageStockAvailabilityModal);
      if (stockAvailabilityModalComponent) {
        modal.remove();
      }
    };

    const _openCheckStockAvailabilityModal = (event) => {

      if (event.target.parentNode.classList.contains('stickyButton')) {
        app.publish('columbo/track', 'sticky-click-and-collect-button', 'click');
      }

      const availabilityModals = document.querySelectorAll(component.config.selectors.productPageStockAvailabilityModal);

      if (availabilityModals.length === 0) {
        return;
      }
      const currentModal = availabilityModals[availabilityModals.length - 1];

      const modalParent = currentModal.parentElement.parentElement;
      modalParent.classList.add(component.config.classNames.modalDialogShow);
      component.publishChannel();
    };

    const _toggleProductAddToBasket = () => {
      const newComponent = document.querySelector(component.config.selectors.stockAvailability);
      if (newComponent.hasAttribute(component.config.attribs.homeDelivery)) {
        const hideHomeDeliveryButton = newComponent.getAttribute(component.config.attribs.homeDelivery) === 'false';

        const homeDeliveryButtons = document.querySelectorAll(component.config.selectors.homeDeliveryButton);

        if (homeDeliveryButtons !== null) {
          if (hideHomeDeliveryButton) {
            homeDeliveryButtons.forEach(homeDeliveryButton => {
              homeDeliveryButton.classList.add(component.config.classNames.hideHomeDelivery);
            });
          } else {
            homeDeliveryButtons.forEach(homeDeliveryButton => {
              homeDeliveryButton.classList.remove(component.config.classNames.hideHomeDelivery);
            });
          }
        }
      }
    };
    component.config = _config;
    component.init = _init;
    component.addEventListener = _addEventListener;
    component.update = _update;
    component.successHandler = _successHandler;
    component.errorHandler = _errorHandler;
    component.cleanupExistingStockAvailabilityModal = _cleanupExistingStockAvailabilityModal;
    component.deleteIfStockAvailabilityModal = _deleteIfStockAvailabilityModal;
    component.updateCheckStockAvailabilityCTA = _updateCheckStockAvailabilityCTA;
    component.checkStockCTAErrorHandler = _checkStockCTAErrorHandler;
    component.openCheckStockAvailabilityModal = _openCheckStockAvailabilityModal;
    component.toggleProductAddToBasket = _toggleProductAddToBasket;
    component.publishChannel = _publishChannel;
    component.showOutOfStockMessage = _showOutOfStockMessage;
    return component;
  };

  return productPageStockAvailability;
});
